import React from 'react'
import AssetAllocation from './components/AssetAllocation'
import './App.css'

function App() {
  return (
    <AssetAllocation/>
  )
}

export default App
